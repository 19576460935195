<template>
  <div class="container">
    <h4 class="title">{{ currentBusiness.name }}</h4>
    <PageNav />
    <slot />
  </div>
</template>

<script>
import PageNav from "@/components/PageNav.vue";

export default {
  components: {
    PageNav,
  },
  computed: {
    links: {
      get() {
        return display_links(this.type)
      },
      set() {
        return display_links(this.type)
      }
    },
    currentBusiness: function () {
      return this.$store.getters.currentBusiness
    },
  },
  methods: {
    hasSubLinks(array) {
      if (array.length > 0) {
        return true
      } else {
        return false
      }
    },
    isActive(routes) {
      const found = routes.find(route => route == this.$route.name);
      if (found) {
        return "is-active"
      } else {
        return null
      }
    },
    async loadData(name) {
     this.$parent.loadData(name)
    }
  }
};

function display_links(type) {
  if (type == 'user') {
    return user_links();
  } else {
    return org_links();
  }
}

function user_links() {
  return [
    {
      path: '/profile', 
      label: 'General', 
      routes: ['profile'],
      sublinks: []
    },
    {
      path: '/payouts', 
      label: 'Payouts', 
      routes: ['payouts'],
      sublinks: []
    },
  ]
}

function org_links() {
  return [
    {
      path: '/settings-general', 
      label: 'General', 
      routes: ['settings-general'],
      sublinks: []
    },
    {
      path: '/settings-deals', 
      label: 'Deal Claim Methods', 
      routes: ['settings-deals'],
      sublinks: []
    },
    {
      path: '/settings-users', 
      label: 'Users', 
      routes: ['settings-users'],
      sublinks: []
    },
    {
      path: '/settings-billing', 
      label: 'Billing', 
      routes: ['settings-billing'],
      sublinks: []
    }
  ]
}

</script>

