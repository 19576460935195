<template>
  <section>
    <nav class="tabs" style="margin:20px 0">
      <ul>
        <li
          v-for="p in pages" 
          :key="p.name"
          :class="isActive(p.name)"
        >
          <a @click="go(p.path, p.name)">{{ p.label }}</a>
        </li>
      </ul>
    </nav>
  </section>
</template>

<script>
export default {
  computed: {
    pages: function() {
      return get_pages()
    }
  },
  methods: {
    go(path, name) {

     if (name != 'report') {
      this.$parent.loadData(name)
     }

     this.$router.push(path);
    },
    isActive(name) {
      let found = false
      if (this.$route.params.status == undefined) {
        found = name == this.$route.name;
      } else {
        found = name == this.$route.params.status;
      }

      if (found) {
        return "is-active"
      } else {
        return null
      }
    }
  }
}

function get_pages() {
  return [
    {
      label: "Overview",
      path: `/organization-report`,
      name: 'report'
    },
    {
      label: "Transactions",
      path: `/organization-transactions/approved`,
      name: 'approved'
    },
    {
      label: "Pending",
      path: `/organization-transactions/pending`,
      name: 'pending'
    },
    {
      label: "Rejected",
      path: `/organization-transactions/rejected`,
      name: 'rejected'
    },
  ]
}
</script>
